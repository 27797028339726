<template>
  <header class="bg-gray-800 text-white fixed w-full top-0 left-0 z-50 py-4">
    <div class="container mx-auto px-4">
      <!-- Logo and Company Name -->
      <div class="flex items-center justify-center">
        <img
          src="https://asiagolf.id/wp-content/uploads/2020/02/ASIA-GOLF-LOGO-BLACK-NEW-2048x409.png"
          alt="Logo"
          class="h-8 mr-2"
        />
        <!-- Adjust size as needed -->
      </div>
    </div>
  </header>
  <div class="mt-20 text-center">
    <h3 class="text-xl font-bold">LIHAT VARIANT DAN HARGA <br>CEK DISINI</h3>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style>
/* Additional styles if needed */
</style>
